<template>
  <div class="content">
    <Header />
    <HeaderInfo />
    <Menu @active_page="func_update_active_page" :active_page="active_page" />
    <div class="container">
      <div class="payment__wrapper">
        <div class="payment__block">
          <h4>{{ user ? "Пополнить счет" : "Быстрая оплата" }}</h4>
          <form @submit.prevent="">
            <div class="form__group" v-if="!user">
              <label>Лицевой счет</label>
              <input type="number" class="num_ls" required v-model="num_ls" />
            </div>
            <div class="form__group" v-else>
              <label>Выберите счет</label>
              <v-select :options="students" label="value" v-model="student" />
            </div>

            <div class="form__group">
              <label>Сумма пополнения</label>
              <input type="number" required v-model="summ" class="summ" />
            </div>
            <!-- <button class="btn-send" @click="func_pay1">
              SberPay клиентам сбербанка
            </button> -->
            <button class="btn-send" @click="func_pay2">Пополнить</button>
          </form>
        </div>
        <div class="payment__info">
          <p>Вы можете пополнить свой счет при помощи банковских карт:</p>
          <p>
            Вы можете оплатить заказ через систему SberPay не вводя никаких данных со
            своей карты, для этого нажмите на кнопку «SberPay клиентам сбербанка» , а
            затем нажмите на кнопку
            <img src="https://bpcard.ru/images/unnamed.png" alt="" style="width: 100px" />
            (Для владельцев карт Сбербанка и мобильного приложения Сбербанк Онлайн)
          </p>

          <p>
            Для оплаты любой другой банковской картой МИР, VISA, MasterCard нажмите кнопку
            «Пополнить»
          </p>
          <p>
            При нажатии на кнопку Пополнить, введите данные Вашей банковской карты и
            укажите сумму, после оплаты, денежные средства сразу попадают на Ваш лицевой
            счет карты питания. ВНИМАНИЕ, наш сайт не хранит номера и сведения о
            банковских картах клиентов, процесс авторизации платежа происходит на шлюзе
            СБЕРБАНКА.
          </p>
          <p>
            УВЕДОМЛЕНИЕ О КОНФИДЕНЦИАЛЬНОСТИ: Это электронное сообщение и любые документы,
            приложенные к нему, содержат конфиденциальную информацию. Настоящим уведомляем
            Вас о том, что если это сообщение не предназначено Вам, использование,
            копирование, распространение информации, содержащейся в настоящем сообщении, а
            также осуществление любых действий на основе этой информации, строго
            запрещено. Если Вы получили это сообщение по ошибке, пожалуйста, сообщите об
            этом отправителю по электронной почте и удалите это сообщение. CONFIDENTIALITY
            NOTICE: This email and any files attached to it are confidential. If you are
            not the intended recipient you are notified that using, copying, distributing
            or taking any action in reliance on the contents of this information is
            strictly prohibited. If you have received this email in error please notify
            the sender and delete this email.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderInfo from "@/components/Header_info.vue";
import Menu from "@/components/Menu.vue";
// import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    HeaderInfo,
    Menu,
    // Footer,
  },
  data() {
    return {
      active_page: 1,
      num_ls: "",
      summ: "",
      student: null,
      students: [],
      balance: null,
      user: false,
      pay_check: false,
      send: false,
    };
  },
  methods: {
    func_update_active_page(value) {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push({
          name: "main",
          params: {
            view_id: value,
          },
        });
      }
    },
    func_get_auth() {
      if (localStorage.getItem("api_key")) {
        this.user = true;
        window.sendRequestAuth("/get_students_pay", {}).then((result) => {
          if (!result) return;
          this.students = result;
        });
      } else {
        this.user = false;
      }
    },
    func_pay() {
      if (this.user) {
        if (this.student && this.summ) {
          if (this.valid_summ()) {
            window.ipay = new window.IPAY({
              api_token: "vvgaud0vue4k19jbort4vke338",
            });
            window.ipayCheckout(
              {
                currency: "RUB",
                description:
                  "ЛС №" +
                  this.students.find((item) => item.id === this.student.id).num_ls,
                amount: this.summ,
              },
              (order) => this.showSuccessfulPurchase(order),
              (order) => this.showFailurefulPurchase(order)
            );
          } else {
            window.soundClick();
            this.$notify({
              group: "foo",
              title: "Ошибка",
              text: "Не корректная сумма",
              type: "error",
            });
            this.send = false;
          }
        } else {
          window.soundClick();
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Заполните все поля",
            type: "error",
          });
        }
      } else {
        if (this.num_ls && this.summ) {
          if (this.valid_summ()) {
            window.sendRequest("/check_ls", { num_ls: this.num_ls }).then((result) => {
              if (!result) return;
              if (result.success) {
                window.ipay = new window.IPAY({
                  api_token: "vvgaud0vue4k19jbort4vke338",
                });
                window.ipayCheckout(
                  {
                    currency: "RUB",
                    description: "ЛС №" + this.num_ls,
                    amount: this.summ,
                  },
                  (order) => this.showSuccessfulPurchase(order),
                  (order) => this.showFailurefulPurchase(order)
                );
              } else {
                window.soundClick();
                this.$notify({
                  group: "foo",
                  title: "Ошибка",
                  text: result.msg,
                  type: "error",
                });
              }
            });
          } else {
            window.soundClick();
            this.$notify({
              group: "foo",
              title: "Ошибка",
              text: "Не корректная сумма",
              type: "error",
            });
            this.send = false;
          }
        } else {
          window.soundClick();
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Заполните все поля",
            type: "error",
          });
          this.send = false;
        }
      }
    },
    func_pay1() {
      if (this.send) return;
      this.send = true;
      if (this.valid_summ()) {
        if (this.user) {
          window
            .sendRequestAuth("/pay_test", {
              student_id: this.student.student_id,
              amount: this.summ,
            })
            .then((result) => {
              this.send = false;
              if (!result) return;
              if (result.error === 1) {
                window.soundClick();
                this.$notify({
                  group: "foo",
                  title: "Ошибка",
                  text: result.msg,
                  type: "error",
                });
                return;
              }
              window.location.href = result.formUrl;
            });
        } else {
          window
            .sendRequest("/pay_test", {
              num_ls: this.num_ls,
              amount: this.summ,
            })
            .then((result) => {
              if (!result) return;
              if (result.error === 1) {
                window.soundClick();
                this.$notify({
                  group: "foo",
                  title: "Ошибка",
                  text: result.msg,
                  type: "error",
                });
                return;
              }
              window.location.href = result.formUrl;
            });
        }
      } else {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Не корректная сумма",
          type: "error",
        });
        this.send = false;
      }
    },
    func_pay2() {
      if (this.send) return;
      this.send = true;
      if (this.valid_summ()) {
        if (this.user) {
          window
            .sendRequestAuth("/pay_u_kassa_test", {
              student_id: this.student.student_id,
              amount: this.summ,
            })
            .then((result) => {
              this.send = false;
              if (!result) return;
              if (result.confirmation.confirmation_url) {
                window.location.href = result.confirmation.confirmation_url;
              } else {
                this.$notify({
                  group: "foo",
                  title: "Ошибка",
                  text: result.msg,
                  type: "error",
                });
              }
            });
        } else {
          window
            .sendRequest("/pay_u_kassa_test", {
              num_ls: this.num_ls,
              amount: this.summ,
            })
            .then((result) => {
              if (!result) return;
              if (result.confirmation.confirmation_url) {
                window.location.href = result.confirmation.confirmation_url;
              } else {
                this.$notify({
                  group: "foo",
                  title: "Ошибка",
                  text: result.msg,
                  type: "error",
                });
              }
            });
        }
      } else {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Не корректная сумма",
          type: "error",
        });
        this.send = false;
      }
    },
    valid_summ() {
      let summ = this.summ;
      if (!summ) return false;
      if (isNaN(Number(summ))) return false;
      if (Number(summ) <= 0 || Number(summ) > 5000) return false;
      return true;
    },
    showSuccessfulPurchase(order) {
      if (this.pay_check) return;
      this.pay_check = true;
      if (!order) return;
      if (this.num_ls) {
        window
          .sendRequest("/pay", {
            num_ls: this.num_ls,
            summ: order.formattedAmount,
            payid: order.orderNumber,
          })
          .then(() => (this.pay_check = false));
      } else {
        window
          .sendRequestAuth("/pay", {
            num_ls: this.student.num_ls,
            summ: order.formattedAmount,
            payid: order.orderNumber,
          })
          .then(() => (this.pay_check = false));
      }
    },
    showFailurefulPurchase() {
      window.soundClick();
      this.$notify({
        group: "foo",
        title: "Ошибка",
        text: "Произошла ошибка оплаты",
        type: "error",
      });
    },
  },
  mounted() {
    this.active_page = 0;
    this.func_get_auth();
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://securepayments.sberbank.ru/payment/docsite/assets/js/ipay.js"
    );
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style>
.btn-send {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
